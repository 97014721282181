// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // TODO: Specify URI for your development environment

  appUrl: 'https://maintenance-__SLUG__.dev.zonarsystems.net',
  // still needs our api url
  apiBase: {
    url: 'https://api-dev.zonarsystems.net/gtcx-maintenance-api/v1'
  },
  coreEntityApiBase: {
    url: 'https://api-dev.zonarsystems.net'
  },
  userPreferenceApiBase: {
    url: 'https://api-dev.zonarsystems.net/alert/v1'
  },
  authEnv: 'development',
  name: 'dev',
  region: 'NA',
  auth: {
    clientID: '6Gj0UOQf77hTs1LbpjdLZM7JSlMux4q8',
    domain: 'zonar-dev.auth0.com',
    audience: 'http://apidev.zonarsystems.net/',
    // These come from the Core APIs as your application is built.
    applicationId: 'c26417fc-3768-4f78-b96e-02770da411d9',
    defaultZonarRole: 'c4a7660b-74cf-4c08-87fb-2b6336a9fc30',
    useRefreshTokens: true
  },
  datadog: {
    applicationId: 'c5b43798-865b-47a3-b73f-47dfdd8281d7',
    clientToken: 'pub1e068f1b0a0555200ba6a33e41abf98b',
    site: 'us5.datadoghq.com',
    tagEnvironment: 'development'
  },
  i18n: {
    supportedLanguages: ['en-CA', 'en-US', 'de-DE', 'en-GB', 'fr-FR', 'es-ES'],
    defaultLanguage: 'en-US'
  },
  production: false,
  gtcxUrl: {
    activityFeed: 'https://activity-feed-ui.dev.zonarsystems.net/',
    maps: 'https://maps.dev.zonarsystems.net/'
  },
  lagacyGtc: {
    diagnostics:
      'https://gtc4dev-cloud.zonarsystems.net/legacy?url=%2Fdiagnostics%2Fjbus',
    gpsUnitHealth:
      'https://gtc4dev-cloud.zonarsystems.net/legacy?url=%2Freports%2Fgps-unit-health',
    faultIq: 'https://tripvision.noregon.com'
  },
  pendo: {
    url: 'https://cdn.pendo.io/agent/static/'
  },
  devCycle: {
    clientId: 'dvc_client_b2f4c7a6_ae2a_4df2_ab9a_eb85fd350737_d9e8211',
    assetSummaryFeature: 'asset-summary'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
